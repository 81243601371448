<template>
  <div>
    <slot name="snackbar"></slot>

    <div id="Header">
      <v-container fluid class="fill-height">
        <v-row class="pa-0 ma-0" v-if="optionsPage.headerPage">
          <v-col cols="12" sm="7" md="7">
            <h1>
              {{ optionsPage.titlePage }}
              <span class="font-weight-light">
                {{ getBusinessName }}
              </span>
            </h1>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-row justify="end" v-if="optionsPage.actionsPage">
              <v-btn
                v-if="orderList"
                color="primary"
                class="ma-2 white--text mr-5 elevation-animations"
                @click="modalSort = true"
                :loading="loading"
                elevation="0"
              >
                <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                Ordenar
              </v-btn>

              <v-btn
                v-if="create"
                color="primary"
                elevation="0"
                class="ma-2 white--text elevation-animations"
                @click="
                  $emit('AddEmit', true);
                  rndKey = Math.random();
                "
                :loading="loading"
              >
                <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                {{ optionsPage.nameBtnCreate }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
        <slot name="table"></slot>
      </v-container>
    </div>
    <slot name="dialogs"></slot>
    <v-dialog
      scrollable
      v-if="orderList"
      persistent
      v-model="modalSort"
      max-width="500px"
    >
      <SortTemplate
        :key="Math.random()"
        :titleSort="optionsPage.titlesort"
        @success="handleSort"
        :DataSort="shorList"
        @cancel="modalSort = false"
      ></SortTemplate>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import sectionDetails from "../../views/business/sections/section-details.vue";
import editSection from "../../views/business/sections/edit-section";
import moment from "moment-timezone";
import "moment/locale/es";
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import SortTemplate from "./sortTemplate.vue";
import TableTemplate from "./TableTemplate.vue";

export default {
  name: "LayautPage",

  components: {
    "section-details": sectionDetails,
    "section-edit": editSection,
    "a-time-picker": TimePicker,
    "a-button": Button,
    SortTemplate,
    TableTemplate,
  },
  props: {
    optionsPage: {
      typeof: Object,
    },
    shorList: {
      typeof: [Object, Array],
    },
    orderList: {
      typeof: Boolean,
    },
    create: {
      typeof: Boolean,
    },
    loading: {
      typeof: Boolean,
    },
    text_title: {
      typeof: String,
      default: "Titulo de Props",
    },
    Table_headers: {
      typeof: String,
      default: "Titulo de Props",
    },
  },
  data() {
    return {
      modalSort: false,
    };
  },

  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
  },
  methods: {
    handleSort() {
      this.snackbarText =
        "Posiciones de las categorías actualizadas correctamente.";
      this.snackbar = true;
      // this.modalSort = false
    },

    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";

      let excelHeader = [
        {
          "Nombre de la categoria": "",
          Posicion: "",
          Activo: "",
        },
      ];

      csvContent += [
        Object.keys(excelHeader[0]).join(";"),
        ...arrData.map(
          (item) =>
            `${item.name};${item.position};${item.active ? "Si" : "No"};`
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `${this.business.shortName}_categorias(${moment()
          .tz("America/Tegucigalpa")
          .format("DD-MM-YYYY")}).csv`
      );
      link.click();
    },
  },

  mounted() {},
};
</script>

<style lang="scss">
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.note {
  border: 1px solid #c9c9c9a2;
  padding: 10px;
  background-color: #8080801f;
}
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f8f8f8;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
.elevation-animations {
  transition: 0.3s ease-in-out;
  &:hover {
    transform: translateY(-3px);
    box-shadow: #01ffcc56 0px 3px 20px !important;
  }
}
</style>
