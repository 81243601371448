var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getMenu,"search":_vm.$store.state.search,"items-per-page":15,"fixed-header":"","loading":_vm.loading,"expanded":_vm.expanded,"sort-by":['position'],"sort-desc":[false, true],"show-expand":"","single-expand":true,"item-key":".key","height":"75vh","footer-props":{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.active",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.activeUpdate(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{staticClass:"pa-4",attrs:{"colspan":headers.length}},[_c('section-details',{key:item['.key'],attrs:{"business":_vm.business,"section":item}})],1)]}},{key:"item.options",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editSection(item)}}},[_vm._v(" Editar ")]),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"small":"","color":"red darken-4"},on:{"click":function($event){_vm.itemToDelete = item;
                _vm.modalDelete = true;}}},[_vm._v(" Eliminar ")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-2",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('v-progress-circular',{attrs:{"width":3,"color":"primary","indeterminate":""}}),_c('p',{class:_vm.$vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }