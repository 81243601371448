<template>
  <div>
    <LayautPage
      text_title="Mesas"
      :orderList="false"
      :create="true"
      :optionsPage="optionsPage"
      :shorList="Tables"
      @AddEmit="openModalAddandEdit"
    >
      <v-snackbar slot="snackbar" top :timeout="3000" v-model="snackbar">
        {{ snackbarText || "Información actualizada correctamente." }}
      </v-snackbar>
      <v-row slot="table">
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="getTables"
            :search="$store.state.search"
            :items-per-page="15"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            :sort-desc="[false, true]"
            :single-expand="false"
            item-key=".key"
            height="60vh"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:[`item.code`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-chip
                    :color="
                      $vuetify.theme.dark
                        ? 'rgba(0, 0, 0, 0.4)'
                        : 'rgba(0, 0, 0, 0.1)'
                    "
                    style="display: inline-block"
                    v-on="on"
                    dark
                    :text-color="$vuetify.theme.dark ? 'white' : 'black'"
                    @click="copyToClipboard(item.code)"
                    >{{ item.code }}</v-chip
                  >
                </template>
                <i class="far fa-copy mr-2"></i>
                <span>Click para copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="activeUpdate(item)"
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.options`]="{ item }" v-if="typeTables">
              <v-btn
                @click="edittables(item)"
                small
                elevation="0"
                color="primary"
              >
                Editar
              </v-btn>
              <v-btn
                elevation="0"
                class="ma-2 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
              >
                Eliminar
              </v-btn>
            </template>

            <template v-slot:loading>
              <div class="ma-2" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </LayautPage>
    <v-dialog
      v-if="modalAddandEdit"
      persistent
      v-model="modalAddandEdit"
      max-width="400px"
    >
      <v-card style="transition: 0.3s ease-in-out">
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="handleCreateCancel"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>

        <v-card-title class="grid-close">
          <b> {{ isEdit ? "Editar mesa " + newItem.code : "Nueva mesa" }}</b>
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <p style="margin-bottom: -5px">Código de la mesa</p>
                <input
                  style="text-transform: uppercase"
                  onkeyup="javascript:this.value=this.value.toUpperCase();"
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el código"
                  v-model="newItem.code"
                />
              </v-col>
            </v-row>
<br>
            <v-divider></v-divider>

            <div class="wrap-actions">
              <v-switch label="Activo" v-model="newItem.active"></v-switch>

              <v-btn
              
                color="primary"
                @click="isEdit ? update_zone() : newZone()"
              >
                {{ isEdit ? "Editar" : "Guardar" }}
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">
            {{ itemToDelete.code }}
          </span>
          ?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LayautPage from "@/components/layautTemplate/LayautPage.vue";
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import MapPicker from "@/components/map-picker.vue";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import SudoGeneralOptions from "./General-sudo-options";
import axios from "axios";
import ledgerMixin from "@/mixins/ledgerMixin";
// import qrOptions from "./qrConfiguration.vue";

export default {
  name: "Table-order",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    LayautPage,
    MapPicker,
  },
  data() {
    return {
      flag_map: true,
      seledzoneget: {
        newLocation: {
          longitude: "",
          latitude: "",
        },
      },
      TablesListGet: [],
      modalDelete: false,
      modalAddandEdit: false,
      isEdit: false,
      mapFlag: false,
      typeTables: false,
      Tables: [],
      itemToDelete: null,
      loading: false,
      optionsPage: {
        titlePage: "Mesas",
        nameBtnCreate: "Crear nueva Mesa",
        headerPage: true,
        titlesort: "Mesas",
        actionsPage: true,
      },
      locationZOne: [],
      newItem: {
        code: null,
        active: null,
      },
      headers: [
        // { text: "Posición", value: "position" },
        { text: "Código", value: "code" },
        {
          text: "Visible en el app",
          value: "active",
          align: "center",
          justify: "center",
        },
        { text: "", value: "options", align: "end" },
      ],
      business: null,
      rules: [(value) => !!value || "Required."],
      tagsToSearch: [],
      search: "",
      Tableselected: {},
      // modalEditSchedule : false,
      scheduleEdited: {},
      snackbarText: "",
      snackbar: "",
      save: false,
    };
  },
  watch: {
    selectedBusiness() {
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
    getTables() {
      return this.Tables.filter(
        (item) => !item.deleted || item.deleted == false
      );
    },
  },
  methods: {
    moment,
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.snackbar = true;
          this.snackbarText = "Copiado al portapapeles";
        },
        (err) => {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    openModalAddandEdit() {
      this.modalAddandEdit = true;
      this.isEdit = false;
    },
    activeUpdate: function (item) {
      this.save = true;
      let docRef = db
        .collection(`businesses/${this.selectedBusiness[".key"]}/tables`)
        .doc(item[".key"]);
      docRef
        .update({
          active: item.active,
          modifiedBy: this.$store.state.user[".key"],
        })
        .then(() => {
          this.snackbarText = `Sección de ${item.code} ${
            item.active ? "activa" : "inactiva"
          }`;
          this.snackbar = true;
          this.save = false;
          this.createLedger({
            source_id: docRef.id,
            source_type: "Tables",
            currentValue: { active: item.active },
            prevValue: { active: !item.active },
          });
        })
        .catch(() => {
          item.active = !item.active;
          this.snackbarText = `Ocurrió un error, inténtelo nuevamente.`;
          this.snackbar = true;
          this.save = false;
        });
    },

    newZone: function () {
      this.newItem.position = -1;
      if (this.newItem.code && this.newItem.active != null) {
        this.save = true;

        this.newItem.deleted = false;

        db.collection(`businesses/${this.selectedBusiness[".key"]}/tables`)
          .add(this.newItem)
          .then((ref) => {
            this.snackbarText = `Zona ${this.newItem.code} agregada existente`;
            this.snackbar = true;

            this.createLedger({
              source_id: ref.id,
              source_type: "Tables",
              currentValue: this.newItem,
            });
            this.newItem = {
              name: null,
              active: null,
            };
            this.save = false;
            this.modalAddandEdit = false;
          })
          .catch((error) => {
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
            this.save = false;
            this.newItem = {
              name: null,
              active: null,
            };

            this.modalAddandEdit = false;
          });

        this.newItem = {
          name: null,
          active: null,
        };
      } else {
        this.snackbar = true;
        this.snackbarText = "Ingrese todos los campos";
      }
    },

    handleCreateCancel() {
      this.newItem = {
        name: null,
        active: null,
      };
      this.modalAddandEdit = false;
    },
    edittables: function (item) {
      this.newItem = item;
      console.debug(this.newItem);

      this.isEdit = true;
      this.modalAddandEdit = true;
      this.save = false;
    },
    update_zone() {
      console.debug(this.newItem);
      this.save = true;
      if (this.newItem.code && this.newItem.active != null) {
        let docRef = db
          .collection(`businesses/${this.selectedBusiness[".key"]}/tables`)
          .doc(this.newItem[".key"]);
        docRef
          .update({
            active: this.newItem.active,
            code: this.newItem.code,
            modifiedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.snackbarText = `Sección de ${this.newItem.code} Actualizada`;
            this.snackbar = true;
            this.save = false;
            this.createLedger({
              source_id: docRef.id,
              source_type: "Tables",
              currentValue: { active: this.newItem.active },
              prevValue: { active: !this.newItem.active },
            });
            this.newItem = {
              code: null,
              active: null,
            };
          })
          .catch(() => {
            this.newItem = {
              code: null,
              active: null,
            };
            item.active = !item.active;
            this.snackbarText = `Ocurrió un error, inténtelo nuevamente.`;
            this.snackbar = true;
            this.save = false;
          });
      }
    },
    handleSort() {
      this.snackbarText =
        "Posiciones de las categorías actualizadas correctamente.";
      this.snackbar = true;
      // this.modalSort = false
    },

    handleClose(order, index) {
      if (order == 1)
        this.newItem.schedule[this.daySelected][index].activeOpens = false;
      else this.newItem.schedule[this.daySelected][index].activeCloses = false;
    },
    changeOpens(time, index) {
      this.newItem.schedule[this.daySelected][index].opensAt =
        moment(time).format("HH:mm:ss");
    },
    changeCloses(time, index) {
      this.newItem.schedule[this.daySelected][index].closesAt =
        moment(time).format("HH:mm:ss");
    },
    removeSchedule: function (position) {
      this.newItem.schedule[this.daySelected].splice(position, 1);
    },

    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";

      let excelHeader = [
        {
          "Nombre de la categoria": "",
          Posicion: "",
          Activo: "",
        },
      ];

      csvContent += [
        Object.keys(excelHeader[0]).join(";"),
        ...arrData.map(
          (item) =>
            `${item.code};${item.position};${item.active ? "Si" : "No"};`
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `${this.business.shortName}_categorias(${moment()
          .tz("America/Tegucigalpa")
          .format("DD-MM-YYYY")}).csv`
      );
      link.click();
    },
    confirmDelete: function () {
      if (this.itemToDelete) {
        db.collection(`businesses/${this.selectedBusiness[".key"]}/tables`)
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Elemento borrado";

            this.createLedger({
              source_id: this.itemToDelete[".key"],
              source_type: "Tables",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: this.itemToDelete,
            });
          })
          .catch(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Occrrió un error, inténtelo nuevamente.";
          });
      }
    },
    async getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });
      this.loading = true;
      this.tagsToSearch = "";
      this.$store.state.search = "";

      let business = this.$binding(
        "business",
        db.collection("businesses").doc(this.selectedBusiness[".key"])
      );

      let Tables = this.$binding(
        "Tables",
        db.collection(`businesses/${this.selectedBusiness[".key"]}/tables`)
      ).then(() => {
        // this.menu = this.menu.filter( item => item.deleted == undefined || item.deleted == false)
      });

      await Promise.all([business, Tables]);
      this.loading = false;

      if (this.business.qrFleetType == "own") {
        this.typeTables = true;
      }
      if (this.business.qrFleetType == "ocho") {
        this.typeTables = false;
      }
      // console.log(this.business.qrFleetType);
      // console.log(this.Tables);
    },
    getTime() {
      let arr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(arr[0]),
        parseInt(arr[1]) - 1,
        parseInt(arr[2]),
        parseInt(arr[3]),
        parseInt(arr[4]),
        parseInt(arr[5]),
        parseInt(arr[6])
      );
    },

    async getTablesList() {
      let TablesGet_ = this.$binding(
        "TablesListGet",
        db
          .collection(`cities/${this.selectedBusiness["qrCity"]}/tables`)
          .where("active", "==", true)
          .where("deleted", "==", false)
      ).then((response) => {
        // console.debug(response);
      });
      return TablesGet_;
    },
  },
  // qrFleetType: "own"  si es own permitir crear rutas delivery y editarlas
  // createdFrom:"Admin-qr" -
  async mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", () => {
        document.body.scrollTop = document.documentElement.scrollTop =
          document.getElementsByClassName("v-data-table__wrapper")[0].scrollTop;
      });

    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "sección de mesas";

    this.getBusinessData();
    await this.getTablesList();
    // console.debug(this.TablesListGet);
    // console.debug(this.getTables);
    // console.debug("Filtro ", this.getFilterZone);
  },

  destroyed() {
    if (this.sectionsReference) this.sectionsReference();

    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>
<style lang="scss">
@import "@/main.scss";
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.note {
  border: 1px solid #c9c9c9a2;
  padding: 10px;
  background-color: #8080801f;
}
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f8f8f8;
}
.save-btn {
 
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.mapa_h {
  min-height: 400px;
  transition: 0.3s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.wrap-actions{
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
height: auto;

}
</style>
