<template>
<div>
    <v-row>
        <v-col cols="12" md="12" sm="12" lg="12">
            <v-card
                class="text-left pa-1 ma-1 flex-grow-1"
                :color="$vuetify.theme.dark?'rgba(0, 0, 0, 0.4)':'rgba(0, 0, 0, 0.1)'"
                elevation="0"
                style="border-radius: 17px !important; overflow:hidden;"
            >
                <v-card-text class="text--primary">
                    <p class="title ma-0" style="color:#00cdbc;">Horario de {{section.name}}</p>
                    <v-divider></v-divider>
                    <div class="days">
                        <!-- <v-row> -->
                            <div 
                                style="text-align:center"
                                v-for="(day, i) in days"
                                :key="i"
                                cols="12" sm="2" md="2">
                               <h3 style="font-weight:bold">{{days[i].text}}</h3>

                                <v-row>
                                    <v-col v-if="!section.schedule[i] || section.schedule[i].length == 0" cols="12" sm="12" md="12">
                                         <v-chip
                                            class="mr-2"
                                            color="grey lighten-2"
                                        >
                                            <span>No definido</span>
                                        </v-chip>
                                    </v-col>
                                    <v-col 
                                        cols="12" sm="12" md="12"
                                        v-for="(schedule, index) of section.schedule[i]"
                                        :key="index">
                                        <v-chip
                                            class="mr-2"
                                            color="grey lighten-1"
                                        >
                                            <span>{{schedule.opensAt}} - {{schedule.closesAt}}</span>
                                        </v-chip>
                                    </v-col>
                                </v-row>
                            </div>
                      
                    </div>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
export default {
  name: "section-details",
  props: ['section', 'business'],
  data() {
   return {
       days: {
            'monday': {'text': 'Lunes', 'order': 0},
            'tuesday': {'text':'Martes', 'order': 1},
            'wednesday': {'text':'Miércoles', 'order': 2},
            'thursday': {'text':'Jueves', 'order': 3},
            'friday': {'text':'Viernes', 'order': 4},
            'saturday': {'text':'Sábado', 'order': 5},
            'sunday': {'text':'Domingo', 'order': 6},
            },
        daysMap : {
            'monday': [],
            'tuesday': [],
            'wednesday': [],
            'thursday':[],
            'friday': [],
            'saturday': [],
            'sunday': [],
            }
    }
  },
  computed: {
      getStatus: function() {
          return this.section.active ? 'Activo' : 'Inactivo'
      },
      getColorStatus : function( ) {
           return this.section.active ? 'green' : 'gray'
      }
   
  },
  mounted() {
    
  },
  methods: {
    handleAnimation: function(anim) {
        this.anim = anim;
        anim.setSpeed(this.animationSpeed);
    },
    showModalSchedule : function(){
        this.scheduleEdited = Object.assign({}, this.section.schedule);        
        this.modalSchedule = true;
    },
    editSection : function(){
        this.editedItem = Object.assign({}, this.section)
        this.modalEditInfo = true;
    },
  
  },
  firestore() {
    return {
      //  users: db.collection("users").where('uid', '>=', 'ocho-')
    };
  }
};
</script>

<style scoped>
    .no-schedule {
        text-align: center
    }
   .days {
        margin-top: 20px;
        display: grid;
        grid-template-columns: 14.2% 14.2% 14.2% 14.2% 14.2% 14.2% 14.2%;
   }
</style>