<template>
  <div>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="save">
      <lottie
        :options="defaultOptions"
        :height="300"
        :width="400"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card class="d-flex flex-column">
      <v-card-title class="grid-close">
        <span class="headline">Administración de categorías</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container v-if="!loading" fluid>
       
              <v-row>
                <v-col cols="12" sm="12" md="12">


                  <schedule-c :loading="loading" :schedule="getSchedule" />
                </v-col>

                <v-col cols="12" class="note mb-4">
                  <small>
                    *Llenar el horario no es obligatorio, si las secciones no
                    cuentan con un horario propio adoptarán el horario del
                    comercio.
                  </small>
                </v-col>
              </v-row>
      
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
           

            <v-col cols="6">
              <v-row justify="end" class="flex-end">
                <v-btn
                  class="save-btn"
                  color="primary"
                 
              
                  @click="updateInfoSection"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog max-width="350" v-model="modalDelete">
      <v-overlay class="loading-center" :value="save">
        <lottie
          :options="defaultOptions"
          :height="300"
          :width="400"
          v-on:animCreated="handleAnimation"
        />
      </v-overlay>

      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar este elemento?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import { mapState } from "vuex";
import scheduleC from "@/components/own-schedule";
import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import ledgerMixin from "@/mixins/ledgerMixin";

export default {
  name: "section-edit",
  props: ["section", "business", "sections", "schedule","type"],
  mixins: [lottieSettings, ledgerMixin],
  components: {
    scheduleC,
    lottie: Lottie,
    "a-time-picker": TimePicker,
    "a-button": Button,
  },
  data() {
    return {
      editedSection: {},
      save: false,
      positionUsed: false,
      waiting: true,
      snackbar: false,
      loading: true,
      snackbarText: "",
      daySelected: "monday",
      item: 0,
      scheduleEdited: {},
      time: null,
      menu2: false,
      iterationArray: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      daysMap: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
      days: {
        monday: { text: "Lunes", closed: false },
        tuesday: { text: "Martes", closed: false },
        wednesday: { text: "Miércoles", closed: false },
        thursday: { text: "Jueves", closed: false },
        friday: { text: "Viernes", closed: false },
        saturday: { text: "Sábado", closed: false },
        sunday: { text: "Domingo", closed: false },
      },
      modalDelete: false,
      position: 0,
      prev: {},
    };
  },
  watch: {
    "editedSection.position"() {
      if (
        this.sections.find(
          (item) =>
            item.position == this.editedSection.position &&
            item[".key"] != this.editedSection[".key"]
        )
      )
        this.positionUsed = true;
      else this.positionUsed = false;
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getSchedule() {
      let days = [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ];
      days.forEach((day) => {
        this.editedSection.schedule[day] = (this.editedSection.schedule[ day ])?  this.editedSection.schedule[ day ].filter((item) => item.opensAt && item.closesAt):[];
      });
      console.debug( this.editedSection.schedule)
      return this.editedSection.schedule;
    },
  },
  mounted() {
    this.scheduleEdited = Object.assign({}, this.schedule);
    this.editedSection = Object.assign({}, this.section);
    this.loading = false;
    this.prevSection = Object.assign({}, this.section);
    console.debug(this.editedSection);

    this.prevSchedule = Object.assign({}, this.schedule);
  },

  methods: {
    moment,

    showSchedule: function (day) {
      this.waiting = false;
      this.daySelected = day;

      setTimeout(() => {
        this.waiting = true;
      }, 100);
    },
    handleClose(order, index) {
      if (order == 1)
        this.scheduleEdited[this.daySelected][index].activeOpens = false;
      else this.scheduleEdited[this.daySelected][index].activeCloses = false;
    },
    updateSuccess: function (type) {
      this.section.name = this.editedSection.name;
      (this.section.position = this.editedSection.position),
        (this.section.active = this.editedSection.active);
      this.save = false;

      if (type) {
        this.snackbar = true;
        this.modalDelete = false;
        this.snackbarText = "Información actualizada";
      } else {
        this.$emit("success");
      }
    },
    updateInfoSection: function (type) {
      this.editedSection.position = Number.parseFloat(
        this.editedSection.position
      );
      if (!this.editedSection.name) {
        this.snackbar = true;
        this.snackbarText = "Ingrese un nombre para la categoría";
        return;
      }
      this.save = true;

      let newSchedule = Object.assign({}, this.scheduleEdited);

      this.iterationArray.forEach((day) => {
        if (!newSchedule[day] || newSchedule[day].length == 0) {
          delete newSchedule[day];
        } else {
          newSchedule[day].forEach((schedule) => {
            delete schedule["activeOpens"];
            delete schedule["activeCloses"];
          });
        }
      });

      db.collection(`businesses/${this.selectedBusiness[".key"]}/qrMenu`)
        .doc(this.section[".key"])
        .update({
          schedule: newSchedule,
        })
        .then(() => {
          this.updateSuccess(type);

          this.createLedger({
            source_id: this.section[".key"],
            source_type: "menu",
            currentValue: Object.assign({}, this.section, this.schedule),
            prevValue: Object.assign({}, this.prevSection, this.prevSchedule),
          });
        })
        .catch(() => {
          this.save = false;
          this.snackbar = true;
          this.snackbarText =
            "Ocurrió un error inesperado, inténtelo nuevamente.";
        });
    },
    addSchedule: function () {
      this.scheduleEdited[this.daySelected].push({
        opensAt: "00:00:00",
        closesAt: "00:00:00",
      });
    },
    changeOpens(time, index) {
      this.scheduleEdited[this.daySelected][index].opensAt =
        moment(time).format("HH:mm:ss");
    },
    changeCloses(time, index) {
      this.scheduleEdited[this.daySelected][index].closesAt =
        moment(time).format("HH:mm:ss");
    },
    removeSchedule: function (position) {
      this.position = position;
      this.modalDelete = true;
      // this.scheduleEdited[this.daySelected].splice(position, 1);
    },
    confirmDelete() {
      this.scheduleEdited[this.daySelected].splice(this.position, 1);
      this.updateInfoSection("deletes");
    },
  },
};
</script>

<style scoped>
.btn-modal-add-tags {
  justify-content: center;
}
.add-tag-ico {
  font-size: 30px !important;
  padding: 10px;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.fa-times {
  color: gray;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.note {
  border: 1px solid #00cdbc;
  padding: 10px;
  border-radius: 13px;
  background-color: #8b8b8b1f;
}
.theme--light.v-list {
  background: white !important;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f06f3d;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
</style>
