<template>
  <div>
    <LayautPage
      text_title="Deliverys"
      :orderList="false"
      :create="typeDelivery"
      :optionsPage="optionsPage"
      :shorList="zones"
      @AddEmit="openModalAddandEdit"
    >
      <v-snackbar slot="snackbar" top :timeout="3000" v-model="snackbar">
        {{ snackbarText || "Información actualizada correctamente." }}
      </v-snackbar>
      <v-row slot="table">
        <v-col>
          <v-btn
            absolute
            right
            top
            class="mt-12"
            color="primary"
            @click="AddZonesbyBussines = true"
          >
            Agregar Zonas
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="zones"
            :search="$store.state.search"
            :items-per-page="15"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            :sort-desc="[false, true]"
            :single-expand="false"
            item-key=".key"
            height="75vh"
            :footer-props="{
              itemsPerPageOptions: [10, 25, 50, 100, 250],
            }"
          >
            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="activeUpdate(item)"
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>
            <template v-slot:[`item.location`]="{ item }">
              <div
                v-if="item.newLocation"
                style="display: flex ; justify-content: center;!important"
              >
                <a
                  style="display: block"
                  :href="
                    'https://www.google.com/maps/search/?api=1&query=' +
                    item.newLocation.latitude +
                    ',' +
                    item.newLocation.longitude
                  "
                  target="_blank"
                >
                  {{
                    item.newLocation.latitude
                      ? parseFloat(item.newLocation.latitude).toFixed(6) + ","
                      : "no disponible"
                  }}
                  {{
                    item.newLocation.longitude
                      ? parseFloat(item.newLocation.longitude).toFixed(6)
                      : ""
                  }}
                  <i class="ml-1 fas fa-external-link-alt"></i>
                </a>
              </div>
            </template>

            <template v-slot:[`item.options`]="{ item }" v-if="typeDelivery">
              <v-btn
                @click="editZone(item)"
                small
                elevation="0"
                color="primary"
              >
                Editar
              </v-btn>
              <v-btn
                elevation="0"
                class="ma-2 white--text"
                @click="
                  itemToDelete = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
              >
                Eliminar
              </v-btn>
            </template>

            <template v-slot:loading>
              <div class="ma-2" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <v-progress-circular
                    :width="3"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </LayautPage>
    <v-dialog
      v-if="AddZonesbyBussines"
      persistent
      v-model="AddZonesbyBussines"
      max-width="500px"
    >
      <v-card style="transition: 0.3s ease-in-out; padding: 10px !important">
        <h1>Agregar Zonas</h1>

        <v-checkbox
          class="ml-3"
          v-for="(zon, index) in getFilterZone"
          :key="index"
          v-model="zonasss"
          :label="zon.name"
          :value="zon"
        ></v-checkbox>


        <v-card-actions>
          <v-row>
            <v-col cols="6"
              ><v-btn outlined rounded text block @click="AddZonesbyBussines = false"> Cancelar </v-btn></v-col
            >
            <v-col cols="6"
              ><v-btn
                rounded
                color="primary"
                block
                @click="newItemZoneByBusiness"
              >
                Aceptar
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="modalAddandEdit"
      persistent
      v-model="modalAddandEdit"
      :fullscreen="mobile"
    >
      <v-card style="transition: 0.3s ease-in-out; padding: 10px !important">
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="handleCreateCancel"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>

        <v-card-title class="grid-close">
          <b>
            {{ isEdit ? "Editar zona " + ZoneSelected.name : "Nueva zona" }}</b
          >
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="5" lg="5" sm="5">
                <p>Nombre de la zona</p>
                <input
                  class="control-input"
                  type="text"
                  placeholder="Ingrese el nombre de la zona"
                  v-model="newItem.name"
                />
              </v-col>
              <v-col cols="12" md="3" lg="3" sm="3">
                <p>Precio</p>
                <input
                  class="control-input"
                  type="number"
                  placeholder="Ingrese el precio de la zona"
                  v-model.number="newItem.cost"
                />
              </v-col>

              <!-- <v-col
                cols="12"
                md="4"
                lg="4"
                sm="4"
                v-if="isEdit == false && getFilterZone.length > 0"
              >
                <p>Zonas ya agregadas</p>
                <v-menu class="mt-2" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      class="rounded-lg mt-3"
                      v-on="on"
                    >
                      {{ seledzoneget.name ? seledzoneget.name : "Zonas" }}
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(zon, index) in getFilterZone"
                      :key="index"
                      @click="seledzoneget = zon"
                    >
                      <v-list-item-title>{{ zon.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col cols="12" md="12" class="mb-4 mapa_h">
                <transition>
                  <MapPicker
                    v-if="flag_map"
                    :longitude="
                      isEdit
                        ? ZoneSelected.newLocation.longitude
                        : seledzoneget.newLocation.longitude
                    "
                    :latitude="
                      isEdit
                        ? ZoneSelected.newLocation.latitude
                        : seledzoneget.newLocation.latitude
                    "
                    @updated="updateZone"
                  />
                </transition>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="pa-0 ma-0">
              <v-col cols="6">
                <v-row justify="start">
                  <v-switch label="Activo" v-model="newItem.active"></v-switch>
                </v-row>
              </v-col>

              <v-col cols="6">
                <v-row justify="end">
                  <v-btn
                    class="save-btn"
                    color="primary"
                    @click="isEdit ? update_zone() : newZone()"
                  >
                    {{ isEdit ? "Editar" : "Guardar" }}
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modal delete  -->
    <v-dialog max-width="400" v-if="itemToDelete" v-model="modalDelete">
      <v-card>
        <v-card-title class="headline">
          Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="mt-3">
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">
            {{ itemToDelete.name }}
          </span>
          ?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LayautPage from "@/components/layautTemplate/LayautPage.vue";
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import MapPicker from "@/components/map-picker.vue";
import lottieSettings from "@/mixins/lottieMixin";
import { mapState } from "vuex";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
// import SudoGeneralOptions from "./General-sudo-options";
import axios from "axios";
import ledgerMixin from "@/mixins/ledgerMixin";
// import qrOptions from "./qrConfiguration.vue";

export default {
  name: "DeliveryPage",
  mixins: [lottieSettings, ledgerMixin],
  components: {
    lottie: Lottie,
    LayautPage,
    MapPicker,
  },
  data() {
    return {
      mobile: screen.width <= 550 ? true : false,
      flag_map: true,
      seledzoneget: {
        newLocation: {
          longitude: "",
          latitude: "",
        },
      },
      ZonesListGet: [],
      modalDelete: false,
      AddZonesbyBussines: false,
      modalAddandEdit: false,
      isEdit: false,
      zonasss: [],
      mapFlag: false,
      typeDelivery: false,
      zones: [],
      itemToDelete: null,
      loading: false,
      optionsPage: {
        titlePage: "Zonas de entrega",
        nameBtnCreate: "Crear nueva zona",
        headerPage: true,
        titlesort: "Rutas",
        actionsPage: true,
      },
      locationZOne: [],
      newItem: {
        name: null,
        cost: null,
        active: null,
      },
      headers: [
        // { text: "Posición", value: "position" },
        { text: "Nombre", value: "name" },
        { text: "Precio", value: "cost" },
        { text: "Coordenadas", value: "location" },
        {
          text: "Visible en el app",
          value: "active",
          align: "center",
          justify: "center",
        },
        { text: "", value: "options", align: "end" },
      ],
      business: null,
      rules: [(value) => !!value || "Required."],
      tagsToSearch: [],
      search: "",

      ZoneSelected: {},

      // modalEditSchedule : false,
      scheduleEdited: {},
      snackbarText: "",
      snackbar: "",
      save: false,
    };
  },
  watch: {
    // seledzoneget:null,
    // ZonesListGet: [],
    seledzoneget(c) {
      this.newItem.name = c.name;
      this.flag_map = false;
      this.seledzoneget = c;
      this.seledzoneget.newLocation = c.location;

      setTimeout(() => {
        this.flag_map = true;
      }, 1000);
    },
    selectedBusiness() {
      this.zones = [];
      this.getBusinessData();
    },
  },
  computed: {
    ...mapState(["selectedBusiness"]),
    getBusinessName() {
      return this.$store.state.selectedBusiness.shortName;
    },
    getFilterZone() {
      var parent = [];
      parent = this.zones.map((e) => {
        if (e.parentId) {
          return e.parentId;
        }
      });
      parent = parent.filter((e) => e);
      return this.ZonesListGet.filter((i) => !parent.includes(i[".key"]));
    },
    typeServiceDelivery() {
      if (this.typeDelivery) {
        return this.zones;
      } else {
        return this.ZonesListGet;
      }
    },
  },
  methods: {
    moment,
    openModalAddandEdit() {
      this.modalAddandEdit = true;
      this.isEdit = false;
    },
    activeUpdate(item) {
      this.save = true;
      let docRef = db
        .collection(`businesses/${this.selectedBusiness[".key"]}/deliveryZones`)
        .doc(item[".key"]);
      docRef
        .update({
          active: item.active,
          modifiedBy: this.$store.state.user[".key"],
        })
        .then(() => {
          this.snackbarText = `Sección de ${item.name} ${
            item.active ? "activa" : "inactiva"
          }`;
          this.snackbar = true;
          this.save = false;
          this.createLedger({
            source_id: docRef.id,
            source_type: "zones",
            currentValue: { active: item.active },
            prevValue: { active: !item.active },
          });
        })
        .catch(() => {
          item.active = !item.active;
          this.snackbarText = `Ocurrió un error, inténtelo nuevamente.`;
          this.snackbar = true;
          this.save = false;
        });
    },

    updateZone(d) {
      this.locationZOne = d;
    },
    async newItemZoneByBusiness() {
      for (let index = 0; index < this.zonasss.length; index++) {
        const element = this.zonasss[index];
        const obj = {
          name: element.name,
          active: false,
          deleted: false,
          parentId: element[".key"],
          location: {
            latitude: element.location.latitude,
            longitude: element.location.longitude,
          },
          newLocation: {
            latitude: element.location.latitude,
            longitude: element.location.longitude,
          },
        };
        console.debug(obj)
   
        await db
          .collection(
            `businesses/${this.selectedBusiness[".key"]}/deliveryZones`
          )
          .add(obj)
          .then((ref) => {
            this.snackbarText = `Zona ${obj.name} agregada existente`;
            this.snackbar = true;

            this.createLedger({
              source_id: ref.id,
              source_type: "zones",
              currentValue: obj,
            });
            console.debug(ref);
            console.debug("con Exito");
          })
          .catch((error) => {
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
            this.save = false;
            this.zonasss = [];
            console.debug(error);
            this.AddZonesbyBussines = false;
            return;
          });
      }
    },
    newZone() {
      this.newItem.position = this.getZones.length + 1;
      if (
        this.newItem.name &&
        this.newItem.cost &&
        this.newItem.active != null
      ) {
        this.save = true;
        if (this.seledzoneget.name) {
          this.newItem.parentId = this.seledzoneget[".key"];
        }
        this.newItem.deleted = false;
        this.newItem.newLocation = new fb.firestore.GeoPoint(
          this.locationZOne[0],
          this.locationZOne[1]
        );
        db.collection(
          `businesses/${this.selectedBusiness[".key"]}/deliveryZones`
        )
          .add(this.newItem)
          .then((ref) => {
            this.snackbarText = `Zona ${this.newItem.name} agregada existente`;
            this.snackbar = true;

            this.createLedger({
              source_id: ref.id,
              source_type: "zones",
              currentValue: this.newItem,
            });
            this.newItem = {
              name: null,
              cost: null,
              active: null,
            };
            this.seledzoneget.newLocation = {
              longitude: "",
              latitude: "",
            };
            this.locationZOne = [];
            this.save = false;
            this.modalAddandEdit = false;
          })
          .catch((error) => {
            this.snackbarText = "Ocurrió un error, inténtelo nuevamente.";
            this.snackbar = true;
            this.save = false;
            this.newItem = {
              name: null,
              cost: null,
              active: null,
            };
            this.seledzoneget.newLocation = {
              longitude: "",
              latitude: "",
            };

            this.modalAddandEdit = false;
          });

        this.newItem = {
          name: null,
          cost: null,
          active: null,
        };
        this.seledzoneget = {
          newLocation: {
            longitude: "",
            latitude: "",
          },
        };
      } else {
        this.snackbar = true;
        this.snackbarText = "Ingrese todos los campos";
      }
    },

    handleCreateCancel() {
      this.newItem = {
        name: null,
        cost: null,
        active: null,
      };
      this.modalAddandEdit = false;
      console.debug(this.getZones);
    },
    editZone: function (item) {
      this.ZoneSelected = item;
      this.newItem.name = item.name;
      this.newItem.cost = parseInt(item.cost);
      this.newItem.active = item.active;
      this.isEdit = true;
      this.modalAddandEdit = true;
      this.save = false;
      // console.debug(this.ZoneSelected);
    },
    update_zone() {
      const item = this.ZoneSelected;
      console.debug(item);
      this.save = true;
      if (
        this.newItem.name &&
        this.newItem.cost &&
        this.newItem.active != null
      ) {
        this.newItem.newLocation = new fb.firestore.GeoPoint(
          this.locationZOne[0],
          this.locationZOne[1]
        );
        this.newItem.cost = parseInt(this.newItem.cost);
        let docRef = db
          .collection(
            `businesses/${this.selectedBusiness[".key"]}/deliveryZones`
          )
          .doc(item[".key"]);
        docRef
          .update({
            active: this.newItem.active,
            name: this.newItem.name,
            cost: this.newItem.cost,
            newLocation: this.newItem.newLocation,
            modifiedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.snackbarText = `Sección de ${item.name} Actualizada`;
            this.snackbar = true;
            this.save = false;
            this.createLedger({
              source_id: docRef.id,
              source_type: "zones",
              currentValue: { active: item.active },
              prevValue: { active: !item.active },
            });
            this.newItem = {
              name: null,
              cost: null,
              active: null,
            };
            this.seledzoneget.newLocation = {
              longitude: "",
              latitude: "",
            };
            this.locationZOne = [];
            this.modalAddandEdit = false;
          })
          .catch(() => {
            this.newItem = {
              name: null,
              cost: null,
              active: null,
            };
            this.seledzoneget.newLocation = {
              longitude: "",
              latitude: "",
            };
            this.locationZOne = [];
            this.modalAddandEdit = false;
            item.active = !item.active;
            this.snackbarText = `Ocurrió un error, inténtelo nuevamente.`;
            this.snackbar = true;
            this.save = false;
          });
      }
    },
    handleSort() {
      this.snackbarText =
        "Posiciones de las categorías actualizadas correctamente.";
      this.snackbar = true;
      // this.modalSort = false
    },

    handleClose(order, index) {
      if (order == 1)
        this.newItem.schedule[this.daySelected][index].activeOpens = false;
      else this.newItem.schedule[this.daySelected][index].activeCloses = false;
    },
    changeOpens(time, index) {
      this.newItem.schedule[this.daySelected][index].opensAt =
        moment(time).format("HH:mm:ss");
    },
    changeCloses(time, index) {
      this.newItem.schedule[this.daySelected][index].closesAt =
        moment(time).format("HH:mm:ss");
    },
    removeSchedule: function (position) {
      this.newItem.schedule[this.daySelected].splice(position, 1);
    },

    csvExport(arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";

      let excelHeader = [
        {
          "Nombre de la categoria": "",
          Posicion: "",
          Activo: "",
        },
      ];

      csvContent += [
        Object.keys(excelHeader[0]).join(";"),
        ...arrData.map(
          (item) =>
            `${item.name};${item.position};${item.active ? "Si" : "No"};`
        ),
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute(
        "download",
        `${this.business.shortName}_categorias(${moment()
          .tz("America/Tegucigalpa")
          .format("DD-MM-YYYY")}).csv`
      );
      link.click();
    },
    confirmDelete: function () {
      if (this.itemToDelete) {
        db.collection(
          `businesses/${this.selectedBusiness[".key"]}/deliveryZones`
        )
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Elemento borrado";

            this.createLedger({
              source_id: this.itemToDelete[".key"],
              source_type: "zones",
              currentValue: {
                deleted: true,
                active: false,
                deletedAt: fb.firestore.Timestamp.fromDate(this.getTime()),
                deletedBy: this.$store.state.user[".key"],
              },
              prevValue: this.itemToDelete,
            });
          })
          .catch(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Occrrió un error, inténtelo nuevamente.";
          });
      }
    },
    async getBusinessData() {
      if (!this.selectedBusiness) this.$router.push({ path: "/" });
      this.loading = true;
      this.tagsToSearch = "";
      this.$store.state.search = "";

      let business = this.$binding(
        "business",
        db.collection("businesses").doc(this.selectedBusiness[".key"])
      );

      let zones = this.$binding(
        "zones",
        db
          .collection(
            `businesses/${this.selectedBusiness[".key"]}/deliveryZones`
          )
          // .where("active", "==", true)
          .where("deleted", "==", false)
      ).then(() => {
        // this.menu = this.menu.filter( item => item.deleted == undefined || item.deleted == false)
      });

      await Promise.all([business, zones]);
      this.loading = false;

      if (this.business.qrFleetType == "own") {
        this.typeDelivery = true;
      }
      if (this.business.qrFleetType == "ocho") {
        this.typeDelivery = false;
      }
      // console.log(this.business.qrFleetType);
      // console.log(this.zones);
    },
    getTime() {
      let arr = moment()
        .tz("America/Tegucigalpa")
        .format("YYYY, MM, DD, HH, mm, ss, 0")
        .split(",");
      return new Date(
        parseInt(arr[0]),
        parseInt(arr[1]) - 1,
        parseInt(arr[2]),
        parseInt(arr[3]),
        parseInt(arr[4]),
        parseInt(arr[5]),
        parseInt(arr[6])
      );
    },

    async getZonesList() {
      let ZonesGet_ = this.$binding(
        "ZonesListGet",
        db
          .collection(`cities/${this.selectedBusiness["qrCity"]}/deliveryZones`)
          .where("active", "==", true)
          .where("deleted", "==", false)
      ).then((response) => {
        // console.debug(response);
      });
      return ZonesGet_;
    },
  },
  // qrFleetType: "own"  si es own permitir crear rutas delivery y editarlas
  // createdFrom:"Admin-qr" -
  async mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", () => {
        document.body.scrollTop = document.documentElement.scrollTop =
          document.getElementsByClassName("v-data-table__wrapper")[0].scrollTop;
      });

    this.$store.state.visibleSearch = true;
    this.$store.state.searchTerm = "sección de delivery";

    this.getBusinessData();
    await this.getZonesList();
  },

  destroyed() {
    if (this.sectionsReference) this.sectionsReference();
    this.$store.commit("setSearchTerm", "");
    this.$store.commit("setSearch", "");
  },
};
</script>
<style lang="scss">
@import "@/main.scss";
.table-sections {
  margin: 5%;
}
.add-schedule {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.grid-close {
  display: grid;
  grid-template-columns: auto auto;
}
.note {
  border: 1px solid #c9c9c9a2;
  padding: 10px;
  background-color: #8080801f;
}
.theme--light.v-list {
  background: white !important;
}
p {
  padding: 0;
  margin: 0;
  color: rgba(0, 0, 0, 0.836);
  font-size: 16px;
}
.add-schedule {
  display: flex;
  justify-content: flex-end;
}
.fa-times {
  color: gray;
}
.delete-schedule-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.delete-schedule {
  margin: 0;
  cursor: pointer;
  padding-right: 5px;
  color: #f8f8f8;
}
.save-btn {
  margin-top: 20px;
}
.actions {
  position: fixed;
  bottom: 0;
  width: 80%;
  bottom: 20px;
  padding-right: 20px;
  background-color: white;
}
.fixed-position {
  z-index: 100;
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}
.mapa_h {
  min-height: 400px;
  transition: 0.3s ease-in-out;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
