<template>

       <v-data-table
              :headers="headers"
              :items="getMenu"
              :search="$store.state.search"
              :items-per-page="15"
              fixed-header
              :loading="loading"
              :expanded.sync="expanded"
              :sort-by="['position']"
              :sort-desc="[false, true]"
              show-expand
              :single-expand="true"
              item-key=".key"
              height="75vh"
              :footer-props="{
                itemsPerPageOptions: [10, 25, 50, 100, 250],
              }"
            >
              <template v-slot:[`item.active`]="{ item }">
                <div style="display: flex ; justify-content: center;!important">
                  <v-switch
                    @change="activeUpdate(item)"
                    v-model="item.active"
                  ></v-switch>
                </div>
              </template>

              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" class="pa-4">
                  <section-details
                    :key="item['.key']"
                    :business="business"
                    :section="item"
                  ></section-details>
                </td>
              </template>

              <template v-slot:[`item.options`]="{ item }">
                <v-btn @click="editSection(item)" small color="primary">
                  Editar
                </v-btn>
                <v-btn
                  class="ma-2 white--text"
                  @click="
                    itemToDelete = item;
                    modalDelete = true;
                  "
                  small
                  color="red darken-4"
                >
                  Eliminar
                </v-btn>
              </template>

              <template v-slot:loading>
                <div class="ma-2" style="position:relative;">
                  <div
                    style="position:absolute; z-index:999; width:100%; height:100%; display:flex; flex-direction:column;justify-content:center; align-items:center;"
                  >
                    <!-- <lottie
                      :options="defaultOptions"
                      :height="300"
                      :width="400"
                      v-on:animCreated="handleAnimation"
                    /> -->
                                      <v-progress-circular
      :width="3"
      color="primary"
      indeterminate
    ></v-progress-circular>
                    <p
                      :class="
                        $vuetify.theme.dark
                          ? 'subtitle-1 primary--text'
                          : 'subtitle-1 black--text'
                      "
                    >
                      Cargando Data
                    </p>
                  </div>
                  <v-skeleton-loader
                    ref="skeleton"
                    type="table-tbody"
                    class="mx-auto d-none d-md-block"
                  ></v-skeleton-loader>
                </div>
              </template>
            </v-data-table>
</template>

<script>
export default {
name:'Table',
components:{

},
props:{
headers:{
    typeof:[Array,Object]
}
},
data(){
    return{

    }
}
}
</script>

<style>

</style>